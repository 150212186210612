import React, { useEffect } from "react"
import Layout from "../../layout/GeneralLayout"
import { Box } from "../../components/commonElements"
import DiceRoller from "../../components/diceRoller"
import ContentBoard from "../../components/contentBoard"
import Footer from "../../components/footer"
import styled from "styled-components"
import SEO from "../../components/seo"
import ShareModal from "../../components/modal/ShareModal"
import FileModal from "../../components/modal/FileModal"
import { useSelector, useDispatch } from "react-redux"
import { getShareLink } from "../../redux/shareLink/actions"
import ToolSettings from "../../components/toolSettings"
import Adthrive from "../../components/adthrive"
import { useToastContext } from "../../components/contextHook/toast"
import { usePageLoadingContext } from "../../components/contextHook/pageLoading"
import { getPersistor } from "../../utils/reduxPersistor"
import LogoProfile from "../../images/tools/d10-image-big.png"
import queryString from "query-string"
import SettingsBoard from "../../components/settingsBoard"

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.theme.containerSize};
  display: grid;
  grid-template-columns: minmax(45rem, 100%) 30rem;
  /* ${props =>
    props.hideSettings
      ? `grid-template-columns: minmax(45rem, 100%) 5rem 30rem;`
      : `grid-template-columns: minmax(45rem, 100%) 34rem 30rem;`} */
  grid-template-rows: minmax(calc(100vh - 12rem), auto);
  // grid-template-columns: 34rem minmax(45rem, auto) 30rem;
  // grid-template-rows: minmax(72rem, 83vh);
  grid-auto-rows: auto;
  grid-template-areas: "Tool RightSide"; //""Tool LeftSide RightSide";
  // "LeftSide Content RightSide";
  gap: 1rem 1rem;
  align-items: stretch;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  /* align-items: stretch;
  justify-content: space-between;
  flex-direction: row; */
  padding: 1rem 1rem;
  @media (min-width: 2000px) {
    grid-template-rows: minmax(87rem, auto);
  }
  @media ${props => props.theme.device.tablet} {
    // max-width: 90rem;
    grid-template-columns: minmax(45rem, 100%) 30rem;
    // grid-template-columns: 34rem minmax(45rem, 56rem);
    grid-template-rows: minmax(53rem, auto); //minmax(65rem, 80vh);
    grid-auto-rows: auto;
    grid-template-areas:
      "Tool RightSide"
      "MiddleAd RightSide"
      "LeftSide RightSide";
    // "LeftSide Content";
  }
  @media ${props => props.theme.device.mobile} {
    // max-width: 50rem;
    grid-template-columns: 100%;
    grid-template-rows: minmax(50rem, auto); //minmax(55rem, auto);
    grid-auto-rows: auto;
    grid-template-areas:
      "Tool"
      "MiddleAd"
      "LeftSide"
      "RightSide";
    // "Content";
    padding-bottom: 0rem;
  }
`

const RightSiderbar = styled(Box)`
  grid-area: RightSide;
  @media ${props => props.theme.device.mobile} {
    display: none;
  }
`

// const SettingsHideBox = styled(GlassBox)`
//   cursor: pointer;
//   margin-bottom: 0rem;
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

// const ToolExpandCollapseBox = styled.div`
//   position: absolute;
//   right: 2rem;
//   bottom: 2rem;
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

// const SettingsShowBox = styled(GlassBox)`
//   ${props => !props.hideSettings && `display: none;`}
//   height: 100%;
//   // @media screen and (max-height: 840px) {
//   //   height: calc(100% - 10rem);
//   // }
//   cursor: pointer;
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

const LeftSidebar = styled(Box)`
  /* ${props => props.hideSettings && `display: none;`} */
  display: none;
  grid-area: LeftSide;
  grid-gap: 1rem 1rem;
  // & > div:nth-last-child(2) {
  //   margin-bottom: 0px;
  // }
  @media ${props => props.theme.device.tablet} {
    display: flex;
  }
`

const LeftSideSettingsBox = styled(Box)`
  grid-area: LeftSideSettings;
  grid-gap: 1rem;
  margin: 0rem;
`

const ToolBox = styled(Box)`
  -webkit-tap-highlight-color: transparent;
  grid-area: Tool;
  z-index: 1;
  margin-bottom: 0rem;
  // @media screen and (max-height: 840px) {
  //   margin-bottom: 10rem;
  // }
  // @media ${props => props.theme.device.tablet} {
  //   margin-bottom: 0rem;
  // }
  /* @media ${props => props.theme.device.mobile} {
    min-height: 70vh;
  } */
`

const MiddleAdBox = styled(Box)`
  grid-area: MiddleAd;
  display: none;
  @media ${props => props.theme.device.tablet} {
    display: flex;
  }
`

const ContentBox = styled(Box)`
  font-size: 1.8rem;
  grid-area: Content;
  background-color: white;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: ${props =>
    `minmax(45rem,${
      props.theme.containerSize.replace("rem", "") - 31
    }rem) 31rem`};
  justify-content: center;
  @media ${props => props.theme.device.mobile} {
    grid-template-columns: auto;
  }
  padding-bottom: 7rem;
  padding-top: 3rem;
`

// const MobileHeaderAdBox = styled(Box)`
//   min-height: auto;
//   align-items: stretch;
//   justify-content: center;
//   margin-top: 0rem;
//   @media ${props => props.theme.device.tablet} {
//     min-height: 10rem;
//     margin-top: 1rem;
//   }
//   @media ${props => props.theme.device.mobile} {
//     min-height: 12.9rem;
//     margin-top: 1rem;
//   }
// `
// const LeftSidebarAd = styled(Adthrive)`
//   /* max-height: 28rem; */
//   user-select: none;
//   & > div {
//     margin: 0;
//   }
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

const RightSidebarAd = styled(Adthrive)`
  justify-content: flex-start;
  & > div {
    margin: 0;
  }
  @media ${props => props.theme.device.mobile} {
    display: none;
  }
`
// const MobileHeaderAd = styled(Adthrive)`
//   display: none;
//   @media ${props => props.theme.device.tablet} {
//     min-height: 10rem;
//     & > div {
//       margin-bottom: 0px;
//     }
//     display: flex;
//   }
//   @media ${props => props.theme.device.mobile} {
//     min-height: 11rem;
//   }
// `

const MobileMiddleAd = styled(Adthrive)`
  @media ${props => props.theme.device.tablet} {
    min-height: 28rem;
    & > div {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`

// const ContentRightSidebarAd = styled(Adthrive)`
//   @media ${props => props.theme.device.mobile} {
//     display: none;
//   }
// `

// const StickyFooterBox = styled(Box)`
//   position: fixed;
//   bottom: 0; /* top: 0; for Top sticky */
//   left: 0;
//   width: 100%;
//   -webkit-overflow-scrolling: touch;
//   background-color: rgba(255, 255, 255, 0.8);
//   z-index: 1000;
//   height: 9rem;
// `

const D10DiceRollerPage = props => {
  // const intuitionResult = useSelector(state => state.modal.result)
  const shareModalOpened = useSelector(state => state.modal.shareModalOpened)
  const fileModalData = useSelector(state => state.modal.fileModalData)
  const { setToast } = useToastContext()
  const { setPageLoading } = usePageLoadingContext()
  // const [hideSettings, setHideSettings] = useState(false)
  const dispatch = useDispatch()
  const diceType = "d10"
  // const location = useLocation()

  useEffect(() => {
    const search = props.location.search
      ? queryString.parse(props.location.search)
      : {}
    const shareId = search.id
    if (shareId) {
      getPersistor().pause()
      const data = {
        sharePage: diceType,
        sharePath: shareId,
      }
      setPageLoading(true)
      dispatch(getShareLink(data)).then(result => {
        setPageLoading(false)
        if (result.error) {
          // toast.warning(result.error)
          setToast(result.error)
        }
      })
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      document.getElementById("ToolRightSidebarAd").style.maxHeight = `calc(${
        document.getElementById("ToolContainer").offsetHeight +
        document.getElementById("ToolContentBox").offsetHeight
      }px - 1rem)`
    }
    window.addEventListener("resize", handleResize)
    handleResize()
  }, [])

  return (
    <>
      {/* <MobileHeaderAd
      id="MobileHeaderAd"
      alignItems="stretch"
      justifyContent="center"
    /> */}
      <Layout>
        <Container
          id="ToolContainer"
          // hideSettings={hideSettings}
        >
          {/* {typeof window !== "undefined" &&
      location.origin.includes("flipsimu.com") && ( //only call at the production
          <Helmet>
            <script
              async
              src="https://cdn.fuseplatform.net/publift/tags/2/2335/fuse.js"
            ></script>
          </Helmet>
        )} */}
          {/* {intuitionResult && <ResultModal />} */}
          {shareModalOpened && <ShareModal />}
          {fileModalData && <FileModal />}
          <LeftSidebar
            gap="1rem"
            // hideSettings={hideSettings}
          >
            {/* <ChallengeBoard />
      <StatsBoard /> */}
            <LeftSideSettingsBox>
              {props.location.search === "" && (
                <ToolSettings page="dice" initialState={true} type={diceType} />
              )}
            </LeftSideSettingsBox>
            {/* <ToolLinkBoardList /> */}
            {/* <SettingsHideBox
              alignItems="center"
              padding=".5rem"
              onClick={() => {
                setHideSettings(true)
              }}
            >
              <Tooltip content="Hide">
                <AngleDoubleRight size={22} />
              </Tooltip>
            </SettingsHideBox> */}
            {/* <DiceVideoAd /> */}
            {/* <LeftSidebarAd
              id="LeftSidebarAd"
              width="100%"
              alignItems="stretch"
              justifyContent="center"
              flex="1"
            /> */}
            {/* <PubliftAd
          id="22299337023"
          desktop
          alignSelf="stretch"
          flex="1"
          alignItems="center"
        />
        <PubliftAd
          id="22299337032"
          mobile
          tablet
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          minHeight={isMobile ? "28rem" : "auto"}
        /> */}
            {/* <FeedbackBoard /> */}
          </LeftSidebar>
          {/* <SettingsShowBox
            gap="1rem"
            padding="2rem 1rem"
            alignItems="center"
            justifyContent="space-between"
            hideSettings={hideSettings}
            onClick={() => {
              setHideSettings(false)
            }}
          >
            <Box gap="3rem">
              <Tooltip content="Customization">
                <Settings size={22} />
              </Tooltip>
            </Box>
          </SettingsShowBox> */}
          <ToolBox gap="1rem" positionBox="relative">
            {/* <DiceTypeBox type={diceType} /> */}
            {/* <DiceBoard diceType={diceType} /> */}
            <SettingsBoard page="dice" type={diceType} />
            <DiceRoller diceType={diceType} />
            {/* <ToolExpandCollapseBox>
              {hideSettings && (
                <Tooltip content="Collapse">
                  <IconButton
                    aria-label="Collapse"
                    onClick={() => {
                      setHideSettings(false)
                    }}
                    icon={<AngleDoubleLeft size={24} />}
                  />
                </Tooltip>
              )}
              {!hideSettings && (
                <Tooltip content="Expand">
                  <IconButton
                    aria-label="Expand"
                    onClick={() => {
                      setHideSettings(true)
                    }}
                    icon={<AngleDoubleRight size={24} />}
                  />
                </Tooltip>
              )}
            </ToolExpandCollapseBox> */}
          </ToolBox>
          <MiddleAdBox>
            <MobileMiddleAd
              id="MobileMiddleAd"
              width="100%"
              alignItems="stretch"
              justifyContent="center"
            />
          </MiddleAdBox>
          <RightSiderbar gap="1rem">
            <Box
              id="ToolRightSidebarAd"
              alignItems="center"
              maxWidth="30rem"
              width="100%"
              height="100%"
              positionBox="absolute"
              zIndex="99"
            >
              <RightSidebarAd
                id="RightSidebarAd"
                width="100%"
                height="100%"
                alignItems="stretch"
                justifyContent="center"
              />
            </Box>
            {/* <StickySidebarAd
          id="22299147479"
          desktop
          alignSelf="stretch"
          flex="1"
          alignItems="center"
        /> */}
          </RightSiderbar>
        </Container>
      </Layout>
      <ContentBox id="ToolContentBox" gap="1rem">
        <ContentBoard file="D10Dice" />
        {/* <ContentRightSidebarAd
        id="ContentRightSidebarAd"
        width="100%"
        alignItems="stretch"
        justifyContent="center"
      /> */}
      </ContentBox>
      <Footer />
      {/* <StickyFooterAd
      id="22299147497"
      desktop
      alignItems="center"
    /> */}
      {/* <StickyFooterBox /> */}
      {/* <StickyFooterAd id="22299147485" mobile tablet alignItems="center" /> */}
    </>
  )
}

export default D10DiceRollerPage

export const Head = () => (
  <SEO
    pathname={"/dice-roller/roll-d10/"}
    toolPage={true}
    titleP="D10 Dice Roller - Roll a Virtual D10 Die"
    descriptionP="Free and fun D10 dice roller. Roll a D10 die for your games and teaching. Multiple dice with various customizations."
    imageP={LogoProfile}
  />
)
